<template>
  <ul class="Socials">
    <li
      v-for="(network, index) in defaultNetworks"
      :key="index"
      :class="`SocialNetwork SocialNetwork-${network.name}`"
    >
      <SocialNetworkButton :network="network" />
    </li>
    <li
      v-if="isMobile"
      class="SocialNetwork SocialNetwork-Messenger"
    >
      <SocialNetworkButton :network="specialNetworks.messenger" />
    </li>
    <li class="SocialNetwork SocialNetwork-Email">
      <SocialNetworkButton :network="specialNetworks.email" />
    </li>
    <li class="SocialNetwork SocialNetwork-CopyUrl">
      <SocialNetworkButton
        :network="specialNetworks.copyUrl"
        is-url-copy
      />
    </li>
    <li
      v-if="isNativeShareAvailable"
      class="SocialNetwork SocialNetwork-genericShare"
    >
      <SocialNetworkButton
        :network="specialNetworks.genericShare"
        is-generic-share
      />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { isMobileDevice } from '@/helpers'
import { facebookAppId } from '@/constants'
import SocialNetworkButton from '@/components/Socials/Partials/SocialNetworkButton.vue'
import useConfigEnv from '@/composables/useConfigEnv'
import { computed, onBeforeMount, ref, useRoute } from '#imports'
import type { ContentNetworks } from '~/domains/thematic/interfaces/content.interface'
import {
  faFacebookF,
  faFacebookMessenger,
  faLinkedinIn,
  faXTwitter,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import {
  faArrowUpFromSquare,
  faEnvelope,
  faLink
} from '@fortawesome/pro-solid-svg-icons'

const props = defineProps<{
  whatsappText?: string
  twitterText?: string
  linkedinText?: string
  mailtoSubject?: string
  mailtoBody?: string
}>()

const configEnv = useConfigEnv()
const route = useRoute()
const appId = ref(facebookAppId)
const isMobile = ref(false)
const isNativeShareAvailable = ref(false)

const url = computed(() => {
  return configEnv.NUXT_ENV_BASE_URL + route.path
})

// We need to encode character '%' in Twitter links, otherwise the link is broken
const twitterTextParsed = computed(() => {
  return props.twitterText?.replaceAll('%', '%25')
})

const defaultNetworks = computed<ContentNetworks>(() => {
  return {
    whatsapp: {
      name: 'WhatsApp',
      link: `https://api.whatsapp.com/send?text=${props.whatsappText}`,
      icon: faWhatsapp,
      id: 'Explore_SocialNetworkButton_WhatsApp'
    },
    facebook: {
      name: 'Facebook',
      link: `https://www.facebook.com/sharer/sharer.php?u=${url.value}`,
      icon: faFacebookF,
      id: 'Explore_SocialNetworkButton_Facebook'
    },
    twitter: {
      name: 'Twitter',
      label: 'X (ex Twitter)',
      link: `https://twitter.com/intent/tweet?text=${twitterTextParsed.value}`,
      icon: faXTwitter,
      id: 'Explore_SocialNetworkButton_Twitter'
    },
    linkedin: {
      name: 'LinkedIn',
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${url.value}&title=${props.linkedinText}&source=HelloAsso`,
      icon: faLinkedinIn,
      id: 'Explore_SocialNetworkButton_LinkedIn'
    }
  }
})

const specialNetworks = computed<ContentNetworks>(() => {
  return {
    messenger: {
      name: 'Messenger',
      link: `fb-messenger://share/?link=${url.value}&app_id=${appId.value}`,
      icon: faFacebookMessenger,
      id: 'Explore_SocialNetworkButton_Messenger'
    },
    email: {
      name: 'Email',
      link: `mailto:?subject=${props.mailtoSubject}&body=${props.mailtoBody}`,
      icon: faEnvelope,
      id: 'Explore_SocialNetworkButton_Email'
    },
    copyUrl: {
      name: 'CopyUrl',
      link: url.value,
      icon: faLink,
      id: 'Explore_SocialNetworkButton_CopyUrl'
    },
    genericShare: {
      name: 'genericShare',
      link: url.value,
      icon: faArrowUpFromSquare,
      id: 'Explore_SocialNetworkButton_GenericShare'
    }
  }
})

onBeforeMount(() => {
  isMobile.value = isMobileDevice()
  isNativeShareAvailable.value = 'canShare' in navigator
})
</script>

<style lang="scss">
@import './style';
</style>
